import { encryptPDPAString, decryptPDPAString } from './crypto';
import _ from 'lodash';

export interface EncryptedResponse {
  encryptedData: string;
}

export const encryptedSecurityInput = (obj: Record<string, unknown> | null): EncryptedResponse => {
  /* SECURITY CLIENT-SERVER encryptedSecurityObject Function */
  if (!_.isObject(obj)) {
    return { encryptedData: '' };
  }

  const jsonObject = JSON.stringify(obj);
  const encryptedData = encryptPDPAString(jsonObject);

  return { encryptedData };
};

export const decryptedSecurityResponse = <T = Record<string, unknown>>(obj: EncryptedResponse): T => {
  /* SECURITY CLIENT-SERVER decryptedSecurityObject Function */
  if (_.isEmpty(obj?.encryptedData)) {
    return {} as T;
  }

  const decryptedData = decryptPDPAString(obj.encryptedData);
  const result: T = JSON.parse(decryptedData);

  return result;
};
