import CryptoJS from "crypto-js";

interface KeyIV {
  key: CryptoJS.lib.WordArray;
  iv: CryptoJS.lib.WordArray;
}

const deriveKeyAndIV = (): KeyIV => {
  const encryptionKey = process.env.REACT_APP_ENCRYPT_PDPA_KEY || '';
  
  if (!encryptionKey) {
    throw new Error("Encryption key is not defined in environment variables");
  }

  const hash = CryptoJS.SHA256(encryptionKey).toString();
  const keyHex = hash.slice(0, 64);  // First 32 bytes (64 hex characters) for key
  const ivHex = hash.slice(0, 32);   // First 16 bytes (32 hex characters) for IV
  const key = CryptoJS.enc.Hex.parse(keyHex); // KEY of 32 bytes
  const iv = CryptoJS.enc.Hex.parse(ivHex);   // IV of 16 bytes
  return { key, iv };
};

export const encryptPDPAString = (str: string): string => {
  try {
    const { key, iv } = deriveKeyAndIV();
    return CryptoJS.AES.encrypt(str, key, { iv }).toString();
  } catch (error) {
    throw new Error("Failed to encrypt string.");
  }
};

export const decryptPDPAString = (encodedStr: string): string => {
  if (!encodedStr) return "";  // Early return for invalid input

  try {
    const { key, iv } = deriveKeyAndIV();
    const decrypted = CryptoJS.AES.decrypt(encodedStr, key, { iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    throw new Error("Failed to decrypt string.");
  }
};
